<template>

  <div class="grid dashboard">
    
    <div class="col-12 md:col-6 xl:col-3">
      <div class="card summary cursor-pointer" v-on:click="filtrarCorridas('espera')">
        <span class="title">Em Espera</span>
        <span class="detail p-mt-4">Buscando Entregador</span>
        <span class="count revenue">{{ countCorridaEspera }}</span>
      </div>
    </div>

    <div class="col-12 md:col-6 xl:col-3">
      <div class="card summary cursor-pointer" v-on:click="filtrarCorridas('buscando')">
        <span class="title">Em Andamento</span>
        <span class="detail p-mt-4">Retirando Entrega</span>
        <span class="count purchases">{{ countCorridaAndamento }}</span>
      </div>
    </div>

    <div class="col-12 md:col-6 xl:col-3">
      <div class="card summary cursor-pointer" v-on:click="filtrarCorridas('iniciada')">
        <span class="title">Iniciadas</span>
        <span class="detail p-mt-4">Corrida Iniciada</span>
        <span class="count visitors">{{ countCorridaIniciada }}</span>
      </div>
    </div>

    <div class="col-12 md:col-6 xl:col-3">
      <div class="card summary cursor-pointer" v-on:click="filtrarCorridas('retornando')">
        <span class="title">Retornando</span>
        <span class="detail p-mt-4">Retornando à Loja</span>
        <span class="count revenue">{{ countCorridaRetornando }}</span>
      </div>
    </div>

  </div>

  <div class="grid dashboard">

    <div class="col-12 lg:col-6">
      <div class="card">
        <DataTable
          :value="listCorridas"
          :paginator="true"
          :rows="8"

          dataKey="id"
          v-model:filters="filtersCorridas"
          filterDisplay="row"

          v-model:expandedRows="corridasExpandidas"

          selectionMode="single"
          v-model:selection="corridaSelecionada"

          responsiveLayout="scroll"
        >
          <template #header>
            <div>TODAS AS CORRIDAS</div>
          </template>

          <Column :expander="true" headerStyle="width: 3rem" />

          <Column header="Entregador" field="entregador.nome" filterField="entregador.nome" :showFilterMenu="false">
            <template #filter="{filterModel,filterCallback}">
              <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
            </template>
          </Column>

          <Column header="Loja" field="empresa.nome" filterField="empresa.nome" :showFilterMenu="false">
            <template #filter="{filterModel,filterCallback}">
              <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
            </template>
          </Column>

          <Column field="status" filterField="status" :showFilterMenu="false" header="Status">
            <template #filter="{filterModel,filterCallback}">
              <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
            </template>
          </Column>

          <Column field="valorEntregador" :showFilterMenu="false" header="Valor">
            <template #body="{data}">
              {{ $utils.formatCurrency(data.valorEntregador) }}
            </template>
          </Column>

          <Column>
            <template #body="{data}">
              <Button
                v-if="data.status === 'Buscando Mercadoria'"
                icon="pi pi-play"
                class="p-button-rounded p-button-success"
                @click="iniciarCorrida(data)"
              />
            </template>
          </Column>

          <template #expansion="{data}">
              <DataTable :value="data.entregas" :paginator="false">
								<Column field="idExterno" header="Pedido"></Column>
                <Column field="status" header="Status"></Column>
                <Column field="data" header="Data de Registro" style="width: 165px"></Column>
                <Column>
                  <template #body="slotProps">
                    <Button
                      v-if="slotProps.data.status === 'Não entregue' && slotProps.data.statusCorrida === 'Iniciada'"
                      icon="pi pi-check"
                      class="p-button-rounded p-button-success"
                      @click="finalizarEntrega(slotProps.data, data)"
                    />
                  </template>
                </Column>
							</DataTable>
          </template>

          <template #footer>
            Corridas (sem filtro): {{ listCorridas ? listCorridas.length : 0 }}
          </template>

        </DataTable>
      </div>
    </div>

    <div class="col-12 lg:col-6">
      <div class="card">
        <GoogleMap
          api-key="AIzaSyB-pXpeqmPDBgxtaT1cMYN2VANbEFQqLrE"
          style="width: 100%; height: 500px"
          :center="mapCenter"
          :zoom="14"
        >
          <Marker 
          v-for="(m, index) in mapMarkers"
          :key="index"
          :options="m"
          />
        </GoogleMap>
      </div>
    </div>
  
  </div>

  <Toast position="top-right" />

</template>

<script>

import moment from 'moment-timezone';
import firebase from 'firebase/app';
import 'firebase/database';

import {FilterMatchMode} from 'primevue/api';
import { GoogleMap, Marker } from 'vue3-google-map'

var refStatusEspera = null;
var refStatusAndamento = null;
var refStatusIniciada = null;
var refEntregador = null;
var refEntregas = null;
var refsCorridas = {
  "refStatusEspera": {},
  "refStatusAndamento": {},
  "refStatusIniciada": {}
};

export default {
  data() {

    return {
      logisticaAtual: null,
      
      countCorridaEspera: 0,
      countCorridaAndamento: 0,
      countCorridaIniciada: 0,
      countCorridaRetornando: 0,

      listCorridas: [],

      corridasExpandidas: [],

      filtersCorridas: {
        'empresa.nome': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'entregador.nome': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'status': {value: null, matchMode: FilterMatchMode.CONTAINS},
      },

      mapCenter: { lat: -16.713681718591975, lng: -49.26569591634313 },
      mapMarkers: { },

      corridaSelecionada: null,

    };

  },
  watch: {

    '$root.logisticaAtual': {
      handler: function(val) {
        this.logisticaAtual = this.$utils.getStdObject(val);
      },
      deep: true,
      immediate: true
    },

    logisticaAtual: function() {
      this.clearData();
      this.clearMap();
      this.initObservers();
    },

    corridaSelecionada: function(val) {
      
      const self = this;

      this.clearMap();

      if(val == null)
        return;

      this.mapMarkers["loja"] = {
        "title": val.empresa.nome,
        "position": {
          "lat": val.empresa.lat, 
          "lng": val.empresa.lng
        },
        "icon": "/icos/origemcorrida.png"
      };

      this.mapCenter = {
        "lat": val.empresa.lat, 
        "lng": val.empresa.lng
      };

      for(const e of val.entregas) {
        this.mapMarkers[e.id] = {
          "title": e.idExterno,
          "position": {
            "lat": e.lat, 
            "lng": e.lng
          },
          "icon": (e.status.toLowerCase() == "entregue")?"/icos/destinocorridaok.png":"/icos/destinocorrida.png"
        };
      }

      if(typeof val.entregador == "object" && val.entregador.id != null) {
        
        refEntregador = firebase.database().ref("Empresas/" + this.logisticaAtual.id)
        .child('LocalMotoboy').child(val.entregador.id).child("l");

        refEntregador.on("value", function (snapshot) {
          if(snapshot.exists()) {
            const coordenadas = Object.values(snapshot.val());

            self.mapCenter = {
              lat: coordenadas[0],
              lng: coordenadas[1],
            };

            self.mapMarkers["entregador"] = {
              "title": "Entregador " + val.entregador.nome,
              "position": {
                "lat": coordenadas[0],
                "lng": coordenadas[1]
              },
              "icon": "/icos/iconemegawebmotoqueiro.png"
            };

          }
        });

      }

    },

	},
  mounted() {

    if(this.$root.logisticaAtual != null) {
      this.logisticaAtual = this.$utils.getStdObject(this.$root.logisticaAtual);
    }

  },
  methods: {

    conformidadeCorrida(dados, no) {

      let corrida = {
        "id": dados.id,
        "status": dados.status,
        "no": no,
        "valorEntregador": dados.valorMotoboy,
        "empresa": {
          "id": dados.empresa.id,
          "nome": dados.empresa.nome,
          "lat": dados.empresa.latitude,
          "lng": dados.empresa.longitude
        },
        "entregas": [],
      };

      if(typeof dados.motorista != "undefined") {
        corrida.entregador = {
          "id": dados.motorista.id,
          "nome": dados.motorista.nome
        };
      } else {
        corrida.entregador = {
          "id": null,
          "nome": "Sem Entregador"
        };
      }

      for(const e of Object.values(dados.entregas)) {

        let entrega = {
          "id": e.id,
          "lat": e.latidite,
          "lng": e.longitude,
          "data": e.data + " " + e.hora,
          "idExterno": e.idExterno,
          "status": e.status,
          "statusCorrida": corrida.status
        };
        
        corrida.entregas.push(entrega);

      }

      return corrida;

    },

    clearData() {

      this.countCorridaEspera = 0;
      this.countCorridaAndamento = 0;
      this.countCorridaIniciada = 0;
      this.countCorridaRetornando = 0;

      this.listCorridas = [];

      this.corridasExpandidas = [];
      this.filters = [];

    },

    initReferences() {

      const ref = firebase.database().ref("Empresas/" + this.logisticaAtual.id);

      if(refStatusEspera != null)
        refStatusEspera.off();

      refStatusEspera = ref.child('CorridaEspera');

      if(refStatusAndamento != null)
        refStatusAndamento.off();

      refStatusAndamento = ref.child('CorridaEmAndamento');

      if(refStatusIniciada != null)
        refStatusIniciada.off();

      refStatusIniciada = ref.child('CorridaIniciada');

      refEntregas = ref.child('Entregas');

    },

    initObservers() {

      const self = this;

      this.clearData();

      for(const ks of Object.keys(refsCorridas)) {
        for(const k of Object.keys(refsCorridas[ks])) {
          refsCorridas[ks][k].off();
          delete refsCorridas[ks][k];
        }
      }

      this.initReferences();

      refStatusEspera.on('child_added', function(snapshot) {
        const val = snapshot.val();
        self.countCorridaEspera++;
        self.listCorridas.push( self.conformidadeCorrida(val, "CorridaEspera") );

        refsCorridas["refStatusEspera"][val.id] = snapshot.ref;
        refsCorridas["refStatusEspera"][val.id].on('value', function(snapshot) {
          if(!snapshot.exists()) return;
          const val = snapshot.val();
          const idx = self.listCorridas.findIndex(e => e.id == val.id && e.no == "CorridaEspera");
          if(idx != -1)
            self.listCorridas[idx] = self.conformidadeCorrida(val, "CorridaEspera");
        });

      });

      refStatusAndamento.on('child_added', function(snapshot) {
        const val = snapshot.val();
        self.countCorridaAndamento++;
        self.listCorridas.push( self.conformidadeCorrida(val, "CorridaEmAndamento") );

        refsCorridas["refStatusAndamento"][val.id] = snapshot.ref;
        refsCorridas["refStatusAndamento"][val.id].on('value', function(snapshot) {
          if(!snapshot.exists()) return;
          const val = snapshot.val();
          const idx = self.listCorridas.findIndex(e => e.id == val.id && e.no == "CorridaEmAndamento");
          if(idx != -1)
            self.listCorridas[idx] = self.conformidadeCorrida(val, "CorridaEmAndamento");
        });

      });

      refStatusIniciada.on('child_added', function(snapshot) {
        const val = snapshot.val();

        if(typeof val.id == "undefined") //Correção para pedidos que ficam apenas com o status Retornando no BD
          return;

        if(val.status.toLowerCase() == "retornando")
          self.countCorridaRetornando++;
        else
          self.countCorridaIniciada++;
        
        self.listCorridas.push( self.conformidadeCorrida(val, "CorridaIniciada") );

        ///////////////////////////////////////////

        refsCorridas["refStatusIniciada"][val.id] = snapshot.ref;
        refsCorridas["refStatusIniciada"][val.id].on('value', function(snapshot) {
          if(!snapshot.exists()) return;

          const val = snapshot.val();
          const idx = self.listCorridas.findIndex(e => e.id == val.id && e.no == "CorridaIniciada");

          if(idx != -1) {

            if(self.listCorridas[idx].status.toLowerCase() == "retornando")
              self.countCorridaRetornando--;
            else
              self.countCorridaIniciada--;

            if(val.status.toLowerCase() == "retornando") {
              self.countCorridaRetornando++;
            } else
              self.countCorridaIniciada++;

            self.listCorridas[idx] = self.conformidadeCorrida(val, "CorridaIniciada");

          }

        });

      });

      /////////////////////////////////////////////////////////////

      refStatusEspera.on('child_removed', function(snapshot) {
        const val = snapshot.val();
        const prevIdx = self.listCorridas.findIndex(e => (e.id == val.id && e.no == "CorridaEspera"));
        if(prevIdx == -1) return;

        self.listCorridas.splice(prevIdx,1);
        self.countCorridaEspera--;

        if(typeof refsCorridas["refStatusEspera"][val.id] != "undefined") {
          refsCorridas["refStatusEspera"][val.id].off();
          delete refsCorridas["refStatusEspera"][val.id];
        }

      });

      refStatusAndamento.on('child_removed', function(snapshot) {
        const val = snapshot.val();
        const prevIdx = self.listCorridas.findIndex(e => e.id == val.id && e.no == "CorridaEmAndamento");
        if(prevIdx == -1) return;

        self.listCorridas.splice(prevIdx,1);
        self.countCorridaAndamento--;

        if(typeof refsCorridas["refStatusAndamento"][val.id] != "undefined") {
          refsCorridas["refStatusAndamento"][val.id].off();
          delete refsCorridas["refStatusAndamento"][val.id];
        }

      });

      refStatusIniciada.on('child_removed', function(snapshot) {
        const val = snapshot.val();
        const prevIdx = self.listCorridas.findIndex(e => e.id == val.id && e.no == "CorridaIniciada");
        if(prevIdx == -1) return;

        if(self.listCorridas[prevIdx].status.toLowerCase() == "retornando")
          self.countCorridaRetornando--;
        else
          self.countCorridaIniciada--;

        self.listCorridas.splice(prevIdx,1);

        if(typeof refsCorridas["refStatusIniciada"][val.id] != "undefined") {
          refsCorridas["refStatusIniciada"][val.id].off();
          delete refsCorridas["refStatusIniciada"][val.id];
        }

      });

    },

    filtrarCorridas(status) {
      const self = this;
      self.filtersCorridas["status"].value = status;
    },

    iniciarCorrida(corrida) {

      const self = this;

      refStatusAndamento.child(corrida.id).once("value", (snapshot) => {

        if(!snapshot.exists()) {

          self.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: "Esse chamado não se encontra mais neste status!",
            life: 5000,
          });

        } else {

          let chamado = snapshot.val();

          chamado.tempoRetirada = {
            dias: 0,
            hora: 0,
            minutos: 10,
            segundos: 0,
          };

          chamado.status = "Iniciada";

          chamado.iniciadaManualmente = true;

          refStatusIniciada.child(corrida.id).set(chamado, () => {

            snapshot.ref.remove().then(() => {

              firebase.database().ref("Usuarios")
              .child(corrida.entregador.id)
              .child("statusCorrida")
              .set("Corrida Iniciada", () => {

                self.$toast.add({
                  severity: "success",
                  summary: "Sucesso!",
                  detail: "Corrida iniciada com sucesso!",
                  life: 3000,
                });

              });

            });

          });

        }

      });
      
    },

    finalizarEntrega(entrega, corrida) {

      const self = this;

      const atualizacao = {
        "status" : "Entregue",
        "timeStampFinal" : moment().tz("America/Sao_Paulo").format("YYYYMMDDHHmmss"),
        "finalizadaManualmente" : true,
        "tempoEntrega" : {
          "dias": 0,
          "hora": 0,
          "minutos": 30,
          "segundos": 0,
        }
      };

      refStatusIniciada.child(corrida.id).child("entregas").child(entrega.id).update(atualizacao, () => {

        refEntregas.child(corrida.id).child(entrega.id).update(atualizacao, () => {

          self.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Entrega finalizada com sucesso!",
            life: 3000,
          });

        });

      });

    },

    clearMap() {

      if(refEntregador != null)
        refEntregador.off();

      this.mapCenter = { lat: -16.713681718591975, lng: -49.26569591634313 };
      this.mapMarkers = {};
    },

  },
  components: { 
    GoogleMap, 
    Marker 
  },

};
</script>
